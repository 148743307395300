.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

.table2 {
    border: 0px}

ul.menu-admin {list-style-type: none;padding-left : 0px;padding-bottom: 0px;padding-top: 0px;margin-bottom: 0px}
ul.menu-admin ul.menu-admin {list-style-type: none;display: none;}
li:hover ul.menu-admin, li li:hover ul.menu-admin {display:block;}

caption {
    caption-side: initial;
}